import { useTranslation } from '@/hooks/translations';
import { Button, Drawer, Grid, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { useUserContext } from '@/contexts/user';
import { BrandScheduledReportRole, restrictRoles } from '@/constants/roleRestrictions';
import { useRestaurantContext } from '@/contexts/restaurant';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { endOfDay, format, formatISO, startOfDay } from 'date-fns';
import { DateRange } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { onPushEvent } from '@/services/lib/gtm';
import { clone } from 'lodash';
import DashboardCard from './DashboardCard';
import {
    APIError,
    ErrorKey,
    ErrorState,
    IDashboard,
    LoadingStates,
    ReportType,
    TIME_RANGE_TEXT,
    initialRevenueReportState,
} from './types';
import TimeRangeForm, { ITimeRangeForm, TimeRangeTypes, getLocaleLang } from '../TimeRangeForm';
import CommonModal from '../CommonModal';
import { IDashboardItem, dashboardItems } from './DashboardItems';
import { dashboardServiceMethods } from './dashboardServiceMethods';
import { PrimaryButton } from '../common';

const drawerStyles: CSSProperties = {
    borderRadius: '32px 32px 0px 0px',
};

const handleAPIError = (
    error: APIError,
    setErrorFunc: React.Dispatch<React.SetStateAction<ErrorState>>,
    errorKey: ErrorKey,
) => {
    setErrorFunc((prevErrors: ErrorState) => ({
        ...prevErrors,
        [errorKey]: error.message,
    }));
};

export default function InfoCards() {
    const { user } = useUserContext();
    const { restaurant, selectedQrGroup, setSelectedQrGroup } = useRestaurantContext();
    const { t } = useTranslation('common');
    const router = useRouter();
    const lang: string = router.query.lang as string;

    const todayDate = new Date(); // Get the current date and time
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90); // Calculate the date x days before today

    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'today',
        from: formatISO(startOfDay(todayDate)),
        to: formatISO(endOfDay(todayDate)),
    });

    const [timeRangeModalOpen, setTimeRangeModalOpen] = useState<boolean>(false);
    const [timeRangeClone, setTimeRangeClone] = useState<ITimeRangeForm>(clone(timeRange));

    const type = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role) ? ReportType.BRAND : ReportType.RESTAURANT;
    const baseName = type === ReportType.RESTAURANT ? 'restaurant' : 'brand';
    const id = type === ReportType.RESTAURANT ? user.restaurantId : restaurant?.brand_id;

    const selectedDasboardKeys = restaurant?.config?.selectedDashboardKeys || [];
    const isUserBrand = BrandScheduledReportRole.includes(user?.userData?.role);

    const [dashboardData, setDashboarData] = useState<IDashboard>(initialRevenueReportState);
    const [errors, setErrors] = useState<ErrorState>({});
    const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

    const timeRangeText = useMemo(() => {
        if (timeRange.type === 'custom' && timeRange.from && timeRange.to) {
            return `${format(new Date(timeRange.from), 'LLLL dd', { locale: getLocaleLang(lang) })}-${format(
                new Date(timeRange.to),
                'LLLL dd',
                { locale: getLocaleLang(lang) },
            )}`;
        }
        return t(TimeRangeTypes[timeRange.type]);
    }, [timeRange]);

    const setSelectedGroupHandler = (group: string) => {
        if (group === 'All') {
            setSelectedQrGroup(null);
            return;
        }
        setSelectedQrGroup(group);
    };

    const filteredDashboardItems = dashboardItems(timeRange, selectedDasboardKeys, dashboardData, baseName);

    const serviceMethods = dashboardServiceMethods(timeRange, baseName, id, selectedQrGroup, timeRange.type);

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(650), {
        defaultMatches: true,
        noSsr: false,
    });

    const fetchData = async (item: IDashboardItem) => {
        if (item.key !== 'revenueorderwow') {
            setLoadingStates((prev: LoadingStates) => ({
                ...prev,
                [item.dataKey]: true,
            }));
        }

        if (item.key !== 'ordersComparedLastWeek') {
            setLoadingStates((prev: LoadingStates) => ({
                ...prev,
                [item.dataKey]: true,
            }));
        }

        const method = serviceMethods[item.apiKey];
        if (method) {
            const { getDataByDay } = method;

            try {
                const dataByDayResult = await getDataByDay();
                setDashboarData((prev: IDashboard) => ({ ...prev, [item.dataKey]: dataByDayResult }));
                setErrors((prev: ErrorState) => ({ ...prev, [item.dataKey]: '' }));
            } catch (error: any) {
                handleAPIError(error, setErrors, item.dataKey);
            } finally {
                setLoadingStates((prev: LoadingStates) => ({ ...prev, [item.dataKey]: false }));
            }
        }
    };

    useEffect(() => {
        filteredDashboardItems.forEach((item) => {
            if (item.enabled) {
                fetchData(item);
            }
        });
    }, [timeRange, selectedQrGroup, restaurant?.id]);

    return filteredDashboardItems.length > 0 ? (
        <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
            {!isMobile && (
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <legend style={{ letterSpacing: '.2vw', color: 'rgba(0,0,0,.6)' }}>
                        <Typography>{t('Revenue Reports')}</Typography>
                    </legend>
                </Grid>
            )}
            <Grid
                item
                xs={isMobile ? 12 : 8}
                sx={{
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                {!isUserBrand && (
                    <Grid item xs={isMobile ? 12 : undefined} sx={{ marginRight: '10px' }}>
                        <Select
                            size="small"
                            value={selectedQrGroup || 'All'}
                            onChange={(e) => {
                                setSelectedGroupHandler(e.target.value || '');
                            }}
                            sx={{
                                marginRight: '10px',
                                borderRadius: '8px',
                                width: '100%',
                                textAlign: 'left',
                                color: '#7d00d4',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: '#7d00d4 !important',
                                },
                            }}
                        >
                            <MenuItem value={'All'}>{t('All QR Groups')}</MenuItem>
                            {restaurant?.qr_groups?.map((group) => (
                                <MenuItem value={group.id}>{group.name}</MenuItem>
                            ))}
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <PrimaryButton
                                    sx={{
                                        marginTop: '10px',
                                        borderRadius: '8px',
                                        height: '40px',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        onPushEvent('vendor_qr-groups_create-icon-button-selected');
                                        router.push('/qr_code');
                                    }}
                                    text={t('Add a Qr Group')}
                                    size="medium"
                                />
                            </Grid>
                        </Select>
                    </Grid>
                )}
                <Grid item xs={isMobile ? 12 : undefined}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setTimeRangeModalOpen(true)}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            height: '40px',
                            width: '100%',
                        }}
                    >
                        {timeRange.type === 'custom' ? timeRangeText : t(TIME_RANGE_TEXT[timeRange.type])}
                        <DateRange sx={{ marginLeft: '4px' }} />
                    </Button>
                </Grid>
            </Grid>

            {filteredDashboardItems.map((item) => {
                if (!item.enabled) return null;

                return (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DashboardCard
                                    headerText={item.dateFilterText}
                                    value={item.value}
                                    valueIcon={item.valueIcon1}
                                    tooltipText={item.tooltipKey}
                                    loading={loadingStates[item.dataKey]}
                                    error={errors[item.dataKey]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            <Drawer
                PaperProps={{ sx: drawerStyles }}
                open={timeRangeModalOpen}
                anchor="bottom"
                onClose={() => setTimeRangeModalOpen(false)}
            >
                <CommonModal
                    onClose={() => {
                        setTimeRangeModalOpen(false);
                        onPushEvent('choosing_date_range_cancel');
                    }}
                    onConfirm={() => {
                        setTimeRangeModalOpen(false);
                        onPushEvent(`user_use_filter_date_${timeRange.type}`);
                        setTimeRange(timeRangeClone);
                    }}
                >
                    <TimeRangeForm
                        value={timeRangeClone}
                        onChange={(val) => {
                            setTimeRangeClone(val);
                        }}
                        options={['today', 'week', 'month']}
                        minDate={minDate}
                    />
                </CommonModal>
            </Drawer>
        </Grid>
    ) : null;
}

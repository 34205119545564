import DashboardService from '@/services/dashboard';
import { ITimeRangeForm, TimeRangeType, formatISOTimezone } from '../TimeRangeForm';

interface ServiceMethods {
    [key: string]: {
        getDataByDay: () => Promise<any>;
    };
}

export const dashboardServiceMethods = (
    timeRange: ITimeRangeForm,
    baseName: string,
    id: string,
    selectedQrGroup: string | null,
    period: TimeRangeType,
): ServiceMethods => {
    const dashboardService = DashboardService.getInstance();
    const qrGroups = selectedQrGroup ? [selectedQrGroup] : null;
    return {
        revenue: {
            getDataByDay: () =>
                dashboardService.getRevenue({
                    name: baseName,
                    id,
                    from: timeRange.from ? formatISOTimezone(timeRange.from) : null,
                    to: timeRange.to ? formatISOTimezone(timeRange.to) : null,
                    qrGroups,
                    period: period === 'today' ? period : 'custom',
                }),
        },
        orders: {
            getDataByDay: () =>
                baseName === 'brand'
                    ? dashboardService
                          .getBrandOrderReport({
                              name: baseName,
                              id,
                              from: timeRange.from ? formatISOTimezone(timeRange.from) : null,
                              to: timeRange.to ? formatISOTimezone(timeRange.to) : null,
                              period: period === 'today' ? period : 'custom',
                          })
                          .then((res) => {
                              console.log(res);

                              return res;
                          })
                    : dashboardService
                          .getOrderCount({
                              name: baseName,
                              id,
                              from: timeRange.from ? formatISOTimezone(timeRange.from) : null,
                              to: timeRange.to ? formatISOTimezone(timeRange.to) : null,
                              qrGroups,
                              period: period === 'today' ? period : 'custom',
                          })
                          .then((res) => {
                              return period === 'today' ? res.today : res.week;
                          }),
        },
        refunds: {
            getDataByDay: () =>
                dashboardService.getRefund({
                    name: baseName,
                    id,
                    from: timeRange.from ? formatISOTimezone(timeRange.from) : null,
                    to: timeRange.to ? formatISOTimezone(timeRange.to) : null,
                    qrGroups,
                    period: period === 'today' ? period : 'custom',
                }),
        },
        dinerFee: {
            getDataByDay: () =>
                dashboardService
                    .getQlubDinerFee({
                        name: baseName,
                        id,
                        ...(period === 'custom' && {
                            from: timeRange.from ? formatISOTimezone(timeRange.from) : null,
                            to: timeRange.to ? formatISOTimezone(timeRange.to) : null,
                        }),
                        qrGroups,
                        period,
                    })
                    .then((res) => res.total_fee_amount),
        },
        revenueorderwow: {
            getDataByDay: () =>
                dashboardService.getRevenueWowReport({ name: baseName, id, qrGroups }).then((res) => res.revenue_wow),
        },
        ordersComparedLastWeek: {
            getDataByDay: () =>
                dashboardService.getOrderReportDiffWeekly({ name: baseName, id, qrGroups }).then((res) => res.orders),
        },
    };
};

import { useTranslation } from '@/hooks/translations';
import { Button, Drawer, Grid, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { useUserContext } from '@/contexts/user';
import { BrandScheduledReportRole, restrictRoles } from '@/constants/roleRestrictions';
import { useRestaurantContext } from '@/contexts/restaurant';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { endOfDay, format, formatISO, startOfDay } from 'date-fns';
import { DateRange } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { onPushEvent } from '@/services/lib/gtm';
import { clone } from 'lodash';
import DashboardService from '@/services/dashboard';
import { IDinerFeeOption } from '@/views/OrdersTableView/types';
import { getCurrencyWithAmount } from '@/common/utility';
import DashboardCard from './DashboardCard';
import { IFetchDashboardDataResponse, ReportType, TIME_RANGE_TEXT } from './types';
import TimeRangeForm, { ITimeRangeForm, TimeRangeTypes, getLocaleLang } from '../TimeRangeForm';
import CommonModal from '../CommonModal';
import { PrimaryButton } from '../common';
import OrderAndRevenue from './OrderAndRevenue';

const drawerStyles: CSSProperties = {
    borderRadius: '32px 32px 0px 0px',
};

export default function NewInfoCards() {
    const dashboardService = DashboardService.getInstance();

    const { user } = useUserContext();
    const { restaurant, selectedQrGroup, setSelectedQrGroup } = useRestaurantContext();
    const { t } = useTranslation('common');
    const router = useRouter();
    const lang: string = router.query.lang as string;

    const todayDate = new Date();
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90);

    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: 'today',
        from: formatISO(startOfDay(todayDate)),
        to: formatISO(endOfDay(todayDate)),
    });

    const [timeRangeModalOpen, setTimeRangeModalOpen] = useState<boolean>(false);
    const [timeRangeClone, setTimeRangeClone] = useState<ITimeRangeForm>(clone(timeRange));

    const type = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role) ? ReportType.BRAND : ReportType.RESTAURANT;
    const baseName = type === ReportType.RESTAURANT ? 'restaurant' : 'brand';
    const id = type === ReportType.RESTAURANT ? user.restaurantId : restaurant?.brand_id;

    const selectedDasboardKeys = restaurant?.config?.selectedDashboardKeys || [];
    const isUserBrand = BrandScheduledReportRole.includes(user?.userData?.role);

    const timeRangeText = useMemo(() => {
        if (timeRange.type === 'custom' && timeRange.from && timeRange.to) {
            return `${format(new Date(timeRange.from), 'LLLL dd', { locale: getLocaleLang(lang) })}-${format(
                new Date(timeRange.to),
                'LLLL dd',
                { locale: getLocaleLang(lang) },
            )}`;
        }
        return t(TimeRangeTypes[timeRange.type]);
    }, [timeRange]);

    const setSelectedGroupHandler = (group: string) => {
        if (group === 'All') {
            setSelectedQrGroup(null);
            return;
        }
        setSelectedQrGroup(group);
    };

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(650), {
        defaultMatches: true,
        noSsr: false,
    });

    const [dashboardData, setDashboardData] = useState<IFetchDashboardDataResponse>();
    const [dashboardItemsLoading, setDashboardItemsLoading] = useState<boolean>(false);
    const [dashboardItemsError, setDashboardItemsError] = useState<string>('');

    const range = timeRange.type === 'custom' ? t('Custom') : t(TimeRangeTypes[timeRange.type]);

    const showQlubDinerFee =
        !restaurant?.restaurant_country?.config?.dinerFeeVisibility ||
        (!restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideAll) &&
            !restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideDashboard));
    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;
    const vendor_enableTipCommission = restaurant?.config?.vendor_enableTipCommission || false;
    const disableTipCardAndChart = restaurant?.restaurant_country?.config?.disableTipCardAndChart || false;
    const hideTip = vendor_enableTipCommission && disableTipCardAndChart;

    const showTipCard = showTip && !hideTip;

    const dashboardItems = [
        {
            key: 'revenue',
            name: 'Revenue',
            dateFilterText: `${t(`Revenue`)} ${range}`,
            tooltipKey: t('Total_Revenue_Selected_Period_TooltipText'),
            enabled: true,
        },
        {
            key: 'discounts',
            name: 'Discount',
            dateFilterText: `${t(`Discount`)} ${range}`,
            tooltipKey: t('Total_Discount_Selected_Period_TooltipText'),
            enabled: false,
        },
        {
            key: 'tips',
            name: 'Tips',
            dateFilterText: `${t(`Tips`)} ${range}`,
            tooltipKey: t('Total_Tip_Amount_Selected_Period_TooltipText'),
            enabled: showTipCard,
        },
        {
            key: 'orders',
            name: 'Orders',
            dateFilterText: `${t(`Orders`)} ${range}`,
            tooltipKey: t('Total_Orders_Selected_Period_TooltipText'),
            enabled: true,
        },
        {
            key: 'refunds',
            name: 'Refunds',
            dateFilterText: `${t(`Refunds`)} ${range}`,
            tooltipKey: t('Total_Refunds_Selected_Period_TooltipText'),
            enabled: true,
        },
        {
            key: 'dinerFee',
            name: 'Diner Fee',
            dateFilterText: `${t(`Diner Fee`)} ${range}`,
            tooltipKey: t('qlub_Diner_Fee_This_Week_TooltipText'),
            enabled: showQlubDinerFee,
        },
    ].map((item) => {
        if (item.key === 'tips' && !showTipCard) return { ...item, enabled: false, valueIcon1: null };
        return {
            ...item,
            enabled: selectedDasboardKeys.length === 0 ? item.enabled : selectedDasboardKeys.includes(item.key),
            valueIcon1: null,
        };
    });

    useEffect(() => {
        setDashboardItemsLoading(true);
        dashboardService
            .getDashboardData({
                name: baseName,
                id,
                qrGroups: selectedQrGroup ? [selectedQrGroup] : null,
                period: timeRange.type,
                from: timeRange.from,
                to: timeRange.to,
            })
            .then((res) => {
                setDashboardData(res);
            })
            .catch((err) => {
                setDashboardItemsError(err.message);
            })
            .finally(() => {
                setDashboardItemsLoading(false);
            });
    }, [timeRange, selectedQrGroup]);

    return dashboardItems.length > 0 ? (
        <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
            {!isMobile && (
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <legend style={{ letterSpacing: '.2vw', color: 'rgba(0,0,0,.6)' }}>
                        <Typography>{t('Revenue Reports')}</Typography>
                    </legend>
                </Grid>
            )}
            <Grid
                item
                xs={isMobile ? 12 : 8}
                sx={{
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                {!isUserBrand && (
                    <Grid item xs={isMobile ? 12 : undefined} sx={{ marginRight: '10px' }}>
                        <Select
                            size="small"
                            value={selectedQrGroup || 'All'}
                            onChange={(e) => {
                                setSelectedGroupHandler(e.target.value || '');
                            }}
                            sx={{
                                marginRight: '10px',
                                borderRadius: '8px',
                                width: '100%',
                                textAlign: 'left',
                                color: '#7d00d4',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: '#7d00d4 !important',
                                },
                            }}
                        >
                            <MenuItem value={'All'}>{t('All QR Groups')}</MenuItem>
                            {restaurant?.qr_groups?.map((group) => (
                                <MenuItem value={group.id}>{group.name}</MenuItem>
                            ))}
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <PrimaryButton
                                    sx={{
                                        marginTop: '10px',
                                        borderRadius: '8px',
                                        height: '40px',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        onPushEvent('vendor_qr-groups_create-icon-button-selected');
                                        router.push('/qr_code');
                                    }}
                                    text={t('Add a Qr Group')}
                                    size="medium"
                                />
                            </Grid>
                        </Select>
                    </Grid>
                )}
                <Grid item xs={isMobile ? 12 : undefined}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setTimeRangeModalOpen(true)}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            height: '40px',
                            width: '100%',
                        }}
                    >
                        {timeRange.type === 'custom' ? timeRangeText : t(TIME_RANGE_TEXT[timeRange.type])}
                        <DateRange sx={{ marginLeft: '4px' }} />
                    </Button>
                </Grid>
            </Grid>

            {dashboardItems.map((item) => {
                if (!item.enabled) return null;
                const { currencySymbol, currencyCode } = dashboardData || {
                    currencySymbol: '',
                    currencyCode: '',
                };
                const value =
                    item.key === 'orders'
                        ? `${dashboardData?.cards.find((card) => card.title === item.key)?.value || 0}`
                        : `${getCurrencyWithAmount(
                              dashboardData?.cards.find((card) => card.title === item.key)?.value,
                              currencySymbol,
                              currencyCode,
                          )}`;

                return (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DashboardCard
                                    headerText={item.dateFilterText}
                                    value={value}
                                    valueIcon={item.valueIcon1}
                                    tooltipText={item.tooltipKey}
                                    loading={dashboardItemsLoading}
                                    error={dashboardItemsError}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            {OrderAndRevenue()}

            <Drawer
                PaperProps={{ sx: drawerStyles }}
                open={timeRangeModalOpen}
                anchor="bottom"
                onClose={() => setTimeRangeModalOpen(false)}
            >
                <CommonModal
                    onClose={() => {
                        setTimeRangeModalOpen(false);
                        onPushEvent('choosing_date_range_cancel');
                    }}
                    onConfirm={() => {
                        setTimeRangeModalOpen(false);
                        onPushEvent(`user_use_filter_date_${timeRange.type}`);
                        setTimeRange(timeRangeClone);
                    }}
                >
                    <TimeRangeForm
                        value={timeRangeClone}
                        onChange={(val) => {
                            setTimeRangeClone(val);
                        }}
                        options={['today', 'yesterday', 'week', 'month']}
                        minDate={minDate}
                        timeSelectEnabled
                    />
                </CommonModal>
            </Drawer>
        </Grid>
    ) : null;
}

import React from 'react';
import { Grid } from '@mui/material';
import { ReportType } from '@/common/utility';

import RevenueChart from '@/components/Dashboard/RevenueChart';
import BestsellingItems from '@/components/Dashboard/BestsellingItems';
import { useRestaurantContext } from '@/contexts/restaurant';

import { useUserContext } from '@/contexts/user';
import { restrictRoles } from '@/constants/roleRestrictions';

import InfoCards from '@/components/Dashboard';
import NewInfoCards from '@/components/Dashboard/infoCards';

export default function Dashboard() {
    const { restaurant } = useRestaurantContext();
    const { user } = useUserContext();

    const reportType = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role)
        ? ReportType.BRAND
        : ReportType.RESTAURANT;

    return (
        <>
            {reportType === ReportType.RESTAURANT ? <NewInfoCards /> : <InfoCards />}
            <Grid container spacing={2}>
                <RevenueChart type={reportType} />
                {restaurant?.config?.vendorShowBestsellingItems && <BestsellingItems />}
            </Grid>
        </>
    );
}

import { useTranslation } from '@/hooks/translations';
import { ReactElement, useMemo } from 'react';
import { getCurrencyWithAmount } from '@/common/utility';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { useRestaurantContext } from '@/contexts/restaurant';
import { IDinerFeeOption } from '@/views/OrdersTableView/types';
import { IDashboard } from './types';
import { ITimeRangeForm, TimeRangeTypes } from '../TimeRangeForm';

export interface IDashboardItem {
    value: string;
    key: string;
    apiKey: string;
    name: string;
    dateFilterText: string;
    dataKey: string;
    tooltipKey: string;
    valueIcon1?: ReactElement | null;
    enabled: boolean;
}

export const dashboardItems = (
    timeRange: ITimeRangeForm,
    dashboardSelectedKeys: string[],
    data: IDashboard,
    baseName: string,
): IDashboardItem[] => {
    const { t } = useTranslation('common');

    const { restaurant } = useRestaurantContext();
    const showQlubDinerFee =
        !restaurant?.restaurant_country?.config?.dinerFeeVisibility ||
        (!restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideAll) &&
            !restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideDashboard));

    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;
    const vendor_enableTipCommission = restaurant?.config?.vendor_enableTipCommission || false;
    const disableTipCardAndChart = restaurant?.restaurant_country?.config?.disableTipCardAndChart || false;
    const hideTip = vendor_enableTipCommission && disableTipCardAndChart;

    const showTipCard = showTip && !hideTip;
    const range = timeRange.type === 'custom' ? t('Custom') : t(TimeRangeTypes[timeRange.type]);

    const getValueByItemKey = (itemKey: string) => {
        const { currency_symbol, currency_code } = data?.revenueData?.currency || {
            currency_symbol: 'AED',
            currency_code: 'ae',
        };
        switch (itemKey) {
            case 'revenue':
                return getCurrencyWithAmount(data?.revenueData?.total_paid_amount, currency_symbol, currency_code);
            case 'discount':
                return getCurrencyWithAmount(
                    data?.revenueData?.total_voucher_amount || data?.revenueData?.total_loyalty_amount,
                    currency_symbol,
                    currency_code,
                );
            case 'tips':
                return getCurrencyWithAmount(data?.revenueData?.total_tip_amount, currency_symbol, currency_code);
            case 'orders':
                if (baseName === 'brand') {
                    return `${data?.orderCount?.orders || 0}`;
                }
                return `${data?.orderCount || data?.orderCount?.week || data?.orderCount?.orders || 0}`;
            case 'refunds':
                return getCurrencyWithAmount(data?.refundData?.total_refund_amount, currency_symbol, currency_code);
            case 'dinerFee':
                return getCurrencyWithAmount(data?.dinerFeeData, currency_symbol, currency_code);
            case 'revenueorderwow':
                return `${((data.revenueWowData || 0) * 100).toFixed(2)} %`;
            case 'ordersComparedLastWeek':
                return `${data.ordersComparedLastWeek || 0}`;
            default:
                return '1';
        }
    };

    return useMemo(() => {
        return [
            {
                key: 'revenue',
                apiKey: 'revenue',
                name: 'Revenue',
                dateFilterText: `${t(`Revenue`)} ${range}`,
                dataKey: 'revenueData',
                tooltipKey: t('Total_Revenue_Selected_Period_TooltipText'),
                value: getValueByItemKey('revenue'),
                enabled: true,
            },
            {
                key: 'discount',
                apiKey: '',
                name: 'Discount',
                dateFilterText: `${t(`Discount`)} ${range}`,
                dataKey: 'revenueData',
                tooltipKey: t('Total_Discount_Selected_Period_TooltipText'),
                value: getValueByItemKey('discount'),
                enabled: false,
            },
            {
                key: 'tips',
                apiKey: '',
                name: 'Tips',
                dateFilterText: `${t(`Tips`)} ${range}`,
                dataKey: 'revenueData',
                tooltipKey: t('Total_Tip_Amount_Selected_Period_TooltipText'),
                value: getValueByItemKey('tips'),
                enabled: showTipCard,
            },
            {
                key: 'orders',
                apiKey: 'orders',
                name: 'Orders',
                dateFilterText: `${t(`Orders`)} ${range}`,
                dataKey: 'orderCount',
                tooltipKey: t('Total_Orders_Selected_Period_TooltipText'),
                value: getValueByItemKey('orders'),
                enabled: true,
            },
            {
                key: 'refunds',
                apiKey: 'refunds',
                name: 'Refunds',
                dateFilterText: `${t(`Refunds`)} ${range}`,
                dataKey: 'refundData',
                tooltipKey: t('Total_Refunds_Selected_Period_TooltipText'),
                value: getValueByItemKey('refunds'),
                enabled: true,
            },
            {
                key: 'dinerFee',
                apiKey: 'dinerFee',
                name: 'Diner Fee',
                dateFilterText: `${t(`Diner Fee`)} ${range}`,
                dataKey: 'dinerFeeData',
                tooltipKey: t('qlub_Diner_Fee_This_Week_TooltipText'),
                value: getValueByItemKey('dinerFee'),
                enabled: showQlubDinerFee,
            },
            {
                key: 'revenueorderwow',
                apiKey: 'revenueorderwow',
                name: 'Revenue & Order WoW',
                dateFilterText: t('Revenue compared last week'),
                dataKey: 'revenueWowData',
                tooltipKey: t('Revenue_Compared_to_Last_Week_TooltipText'),
                value: getValueByItemKey('revenueorderwow'),
                valueIcon1:
                    data?.revenueWowData > 0 ? (
                        <ArrowUpwardRoundedIcon />
                    ) : data?.revenueWowData < 0 ? (
                        <ArrowDownwardRoundedIcon />
                    ) : null,
                valueIcon2:
                    data?.orderWowData > 0 ? (
                        <ArrowUpwardRoundedIcon />
                    ) : data?.orderWowData < 0 ? (
                        <ArrowDownwardRoundedIcon />
                    ) : null,
                enabled: true,
            },
            {
                key: 'ordersComparedLastWeek',
                apiKey: 'ordersComparedLastWeek',
                name: 'Orders Compared Last Week',
                dateFilterText: t('Orders compared last week'),
                dataKey: 'ordersComparedLastWeek',
                tooltipKey: t('Orders_Compared_to_Last_Week_TooltipText'),
                value: getValueByItemKey('ordersComparedLastWeek'),
                valueIcon1:
                    data?.ordersComparedLastWeek > 0 ? (
                        <ArrowUpwardRoundedIcon />
                    ) : data?.ordersComparedLastWeek < 0 ? (
                        <ArrowDownwardRoundedIcon />
                    ) : null,
                enabled: true,
            },
        ].map((item) => {
            if (item.key === 'tips' && !showTipCard) return { ...item, enabled: false };
            return {
                ...item,
                enabled: dashboardSelectedKeys.length === 0 ? item.enabled : dashboardSelectedKeys.includes(item.key),
            };
        });
    }, [t, range, dashboardSelectedKeys]);
};
